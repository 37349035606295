// Based on
// https://www.ryanbateman.space/blog/tutorial-gatsbyjs-for-drupalers-or-how-to-jamstack-ify-your-drupal-site-with-gatsbyjs/
import { graphql } from "gatsby"
import moment from 'moment'
import React from "react"

import Layout from '../components/layout'

const BlogPost = ({ data }) => (
  <Layout>
    <article>
      <h1>{data.nodeForKids.title}</h1>
      <i><p className="publication-date">{moment.unix(data.nodeForKids.created).format('DD MMMM, YYYY - h:mm A')}</p></i>
      <span dangerouslySetInnerHTML={{__html: data.nodeForKids.body.processed}}></span>
    </article>
  </Layout>
)

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    nodeForKids (path: { alias: { eq: $slug}}) {
      title
      created
      changed
      body {
	      processed
      }
    }
  }
`
